.card {
  width: min(100%, 400px);
  height: 240px;
  border-radius: 10px;
  background-size: cover;
  background-image: url('./images/palm.jpeg');
  filter: contrast(90%);
  transition: all 0.6s cubic-bezier(0.43, 0.41, 0.22, 0.91);
  box-shadow:
    0 6.7px 5.3px rgba(0, 0, 0, 0.03),
    0 22.3px 17.9px rgba(202, 150, 150, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07);
}

.card:hover {
  filter: contrast(100%);
}

.image {
  width: 100%;
  height: 100%;
}

.figcaption {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.cardText {
  width: 100%;
  color: white;
  padding: 0 10px 0 10px;
  backdrop-filter: blur(10px);
  border-radius: 10px;
  min-width: 0;
}

.cardText > h2 {
  color: white;
  font-size: 1.1rem;
  letter-spacing: 1px;
  margin-bottom: 2px;
}

.cardText > h2 > span {
  color: #bd2893;
  font-size: 0.8rem;
}

.cardText > p {
  min-width: 0;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 950px) {
  .card {
    width: min(100%, 500px);
  }
}

@media (max-width: 576px) {
  .card {
    filter: none;
    transition: none;
  }

  .card:first-child {
    margin-top: 2vh;
  }
}
