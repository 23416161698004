.container {
  padding: 30px;
  margin: 0;
  /* height: 100vh; */
}

.column {
  min-height: 100%;
  max-width: fit-content;
}

.headerColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.header {
  margin-bottom: 20px;
  height: fit-content;
}

.profilePicture {
  display: block;
  max-width: 120px;
  aspect-ratio: 1 / 1;
  border-radius: 20px;
  pointer-events: none;
}

.userName {
  font-weight: 600;
  margin: 0;
}
