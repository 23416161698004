.container {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow:
    0 6.7px 5.3px rgba(0, 0, 0, 0.03),
    0 22.3px 17.9px rgba(202, 150, 150, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07);
  border: 1px solid rgb(173, 173, 173, 0.5);
}

.container:hover {
  cursor: pointer;
}

.requestHeader {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  margin-top: 5px;
}

.requestContent {
  padding: 10px;
}

.title {
  color: #030303;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.date {
  color: #545454;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0;
}

.button {
  color: black;
  background-color: #c1efff;
  width: 120px;
  border: 0px solid;
  align-self: center;
  margin: 0 10px 20px 0;
}

.button:hover {
  color: black;
  background-color: #ffdba4;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
}

.imageContainer {
  position: relative;
}

.image {
  border-radius: 1px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  filter: contrast(70%);
  max-height: 182px;
  transition: all 0.6s cubic-bezier(0.43, 0.41, 0.22, 0.91);
  width: 100%;
}

.image:hover {
  filter: contrast(100%);
}

.dividerVertical {
  position: absolute;
  top: 39%;
  width: 100%;
  height: 41px;
  background-color: #7f7f7f;
  border-radius: 2px;
  opacity: 0.9;
  transform: rotate(28deg);
}

.closedHeader {
  color: black;
  text-align: center;
}

@media (max-width: 576px) {
  .container {
    max-width: 100%;
    margin: 20px 0 0 0;
  }

  .requestImage {
    display: none;
  }

  .image {
    height: 240px;
  }
}
