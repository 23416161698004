.button {
  width: fit-content;
}

.formButton {
  margin: 0 10px;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
}

.pHeader {
  margin: 10px 0 10px 10px;
  width: 75px;
}

.selectBox {
  height: 40px;
  border-radius: 5px;
}

@media (max-width: 576px) {
  .statusColumn {
    margin-top: 10px;
  }

  .button {
    margin: 0 auto;
  }
}

@media screen and (min-width: 1400px) {
  .requestCol {
    width: 20%;
  }
}
