.circle {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: white;
  border: .5px solid #340900;
  color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  font-size: 0.9rem;
  box-shadow: 0.1em 0.1em 0 0 rgba(15, 28, 63, 0.125);
}

.circle:hover {
  background-color: #ff2b00;
  color: white;
}

.lastCircle {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.inStack {
  margin-left: -16px;
  letter-spacing: 0.1rem;
  font-size: 0.62rem;
  transition: all 0.5s ease-in-out;
}

.inStack:hover {
  margin-top: -3px;
  transform: scale(1.1);
}

.inList {
  margin: 0 10px 0 5px;
  width: 30px;
  height: 30px;
}

.inList:hover {
  background-color: inherit;
  color: inherit;
  margin-top: 0;
  transform: none;
}
