.row {
  margin-bottom: 16px;
}

.profileText {
  text-align: justify;
}

.inputField {
  width: fit-content;
}

.form {
  width: 50%;
}

.btn {
  width: 100%;
}

.spinner {
  margin: auto;
  margin-bottom: 1rem;
}

@media (max-width: 992px) {
  .form {
    width: 100%;
  }


  .inputField {
    width: 100%;
  }
}
