.row {
  min-height: 100vh;
}

.column {
  min-height: 100vh;
  padding: 0;
  min-width: 0;
}

.mainColumn {
  margin-left: 4%;
  margin-bottom: 2rem;
}

.sticky {
  position: fixed;
  height: 100%;
  /* max-width: 5%; */
  left: 0;
  top: 0;
  z-index: 300;
}

@media (max-width: 576px) {
  .mainColumn {
    margin-left: 0;
  }

  .column {
    min-height: 0;
    min-width: unset;
    height: auto;
  }

  .sticky {
    position: sticky;
    z-index: 1600;
  }
}
