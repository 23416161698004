.container {
  margin-top: 15px;
}

.neighborhoodImg {
  max-height: 5em;
  min-width: 5em;
}

.neighborhoodDescription {
  margin: 10px 0px;
  padding: 0;
}

.btn {
  margin-right: 5px;
}

.membersContainer {
  display: flex;
  align-items: center;
}

.deleteBtn {
  display: inline-block;
  padding: 3px 9px;
}

.editBtn {
  display: inline-block;
  padding: 3px 9px;
  margin-right: 8px;
}

@media (max-width: 576px) {
  .btn {
    min-width: 55%;
    margin-top: 5px;
  }
}
