.nav {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  height: 100%;
  padding: 1vw;
}

.logo {
  font-size: 1.6rem;
  transition: font-size 0.2s;
}

.link {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.link:hover {
  cursor: pointer;
}

.profilePicture {
  width: 36px;
  height: 30px;
  border-radius: 100px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.homeLink {
  overflow: visible;
  fill: rgb(3, 3, 3);
  font-size: 42px;
  width: 42px;
  height: 42px;
  color: #030303;
}

.exploreLink {
  overflow: visible;
  fill: rgb(3, 3, 3);
  font-size: 43px;
  width: 43px;
  height: 38px;
  color: #030303;
}

.notificationsLink {
  overflow: visible;
  fill: rgb(3, 3, 3);
  font-size: 29px;
  width: 29px;
  height: 36px;
  color: #030303;
}

.navbarCollapseLink {
  font-weight: 500;
}

.navIcon {
  color: white;
  font-size: 1.7rem;
  /* stroke: #340900; */
  stroke-width: 40px;
}

.navIcon:hover, .logo:hover {
  color: #ff2b00;
}

.navIcon:active, .logo:active {
  color: #e92700;
}

.logo:hover {
  font-size: 1.7rem;
}

.homeIcon:hover {
  animation: flip 1s;
}

.compassIcon:hover {
  animation: rotate 1.8s;
}

.bellIcon:hover {
  animation: ring 0.2s;
}

.logOutIcon {
  margin-left: 5px;
}

.logOutIcon:hover {
  animation: leave 0.4s;
}

@keyframes flip {
  0% {
    -ms-transform: rotateY(0);
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
    -o-transform: rotateY(0);
    transform: rotateY(0);
  }
  100% {
    -ms-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
}

@keyframes rotate {
  0% {
    -ms-transform: rotate(0);
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0%);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

@keyframes leave {
  0% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(0);
  }
}

@media (max-width: 576px) {
  .nav {
    background: none;
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 1vh 5vw 1vh;
    height: 100%;
  }

  .link {
    margin: 0 10px;
  }
}
