img {
  width: 100%;
}

.wrapper {
  color: #3e4756;
  background: #fff;
  background-image: radial-gradient(at 3.6% 13.8%, #f8d5e0 0px, transparent 50%),
    radial-gradient(at 96.3% 93.8%, #fad3ca 0px, transparent 50%);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.header {
  /* position: fixed; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-between; */
  /* width: 100%; */
  /* top: 0; */
  /* right: 0; */
  padding: 16px 8%;
  z-index: 10;
  transition: all 0.5s ease;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
}

.header.active {
  background: rgba(255, 255, 255, 0.8);
}

.logo {
  color: #3e4756;
  font-size: 1.5rem;
  font-weight: 700;
}

.logo i {
  font-size: 2rem;
  margin-right: 4px;
}

.navBar {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0 0 0 auto;
  list-style: none;
}

.navBar li {
  margin-right: 24px;
  font-weight: 500;
}

.navItem {
  color: #3e4756;
  font-size: 1.2rem;
  transition: all 0.5s ease;
  align-self: center;
  margin-right: 1rem;
}

.navItem:hover,
.navItem.active {
  color: #ff4e2a;
}

.menuIcon {
  display: none;
  font-size: 2rem;
  color: #3e4756;
  cursor: pointer;
  z-index: 20;
}

/* All Buttons Styles */

.btn {
  width: 6.5rem;
  color: white;
  /* position: relative; */
  /* display: inline-flex; */
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  outline: 0;
  border: 0;
  border-radius: 10px;
  overflow: hidden;
  font-size: 1rem;
  font-weight: 700;
  box-shadow: 0 0 4px 2px #00000020;
  transition: all 0.3s ease;
  z-index: 0;
  cursor: pointer;
}

.loginBtn {
  padding: 0.375rem 1rem;
  margin-left: 10px;
}

.btn:active {
  transform: scale(0.97);
}

@keyframes effect {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.signUpLink:hover {
  color: white;
}

/* --------------- */
/* SECTION - HOME */

.home {
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 80px;
  place-items: center;
  min-height: 80vh;
  width: 100%;
  padding: 60px 8% 0;
}

.home .content {
  color: #3e4756;
}

.home .content h1 {
  font-size: clamp(2rem, 3vw, 2.5rem);
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 24px;
}

.home .content p {
  /* font-size: clamp(0.8rem, 3vw, 1.1rem); */
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 32px;
}

.imageContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 5px;
}

.imageContainer .item {
  max-width: 260px;
  aspect-ratio: 4/3;
  border-radius: 6px;
  pointer-events: none;
  opacity: 0;
  animation: fadeIn 0.5s linear 1 forwards;
  animation-delay: calc(0.6s + (0.4s * var(--i)));
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* --------------------------- */
/* SECTION - GETTING STARTED */

.gettingStarted {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
  padding: 80px 8% 0;
}

.gettingStarted h1 {
  font-size: clamp(1.8rem, 3vw, 2.1rem);
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 50px;
}

.gettingStarted h1::before {
  content: '';
  top: 66%;
  left: 0;
  width: 100%;
  height: 12px;
  border-radius: 5px;
  background: linear-gradient(90deg, #f3b7aa 0%, #f7cad9 50%, #e4caf7 100%);
  z-index: -1;
}

.gettingStarted .cardContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 40px;
}

.card {
  display: grid;
  grid-auto-flow: dense;
  grid-template-rows: 1fr 2fr;
  text-align: center;
  max-width: 300px;
  padding: 30px 20px;
  background: #fff;
  /* border: 3px solid #f3b7aa; */
  border-radius: 10px;
  box-shadow: 0 0 4px 2px #00000020;
  transition:
    background 0.3s linear,
    border 0.4s ease-in-out;
  cursor: default;
}

.card:hover {
  background: linear-gradient(160deg, #fbe7e3e5 30%, #ffdfe9c4 80%);
  /* border: 3px solid #ff4e2a; */
}

.card h2 {
  font-size: clamp(1rem, 3vw, 1.2rem);
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 16px;
}

/* ------------------ */
/* SECTION - EXPLORE */

.explore {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 30px;
  overflow-x: hidden;
  padding: 100px 8% 0;
  min-height: 100vh;
}

.explore h1 {
  position: relative;
  font-size: clamp(1.8rem, 3vw, 2.1rem);
  font-weight: 600;
  line-height: 1.3;
  text-align: center;
}

.explore h1::before {
  content: '';
  position: absolute;
  top: 66%;
  left: 0;
  width: 100%;
  height: 12px;
  border-radius: 5px;
  background: linear-gradient(90deg, #f3b7aa 0%, #f7cad9 50%, #e4caf7 100%);
  z-index: -1;
}

.container {
  width: 100%;
  /* display: flex; */
}

.swiperSlide {
  max-width: 350px;
  height: 410px;
  margin: 20px 0 40px;
  border-radius: 10px;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  user-select: none;
  cursor: grab;
}

.slideContent {
  display: grid;
  grid-auto-flow: dense;
  /* grid-template-rows: 3fr 1fr 3fr; */
  grid-auto-rows: 1fr;
  gap: 10px;
  height: 100%;
  background: #fff;
  /* border: 3px solid #f3b7aa; */
  border-radius: inherit;
  transition: border 0.2s ease-in;
}

.swiperSlide:hover .slide-content {
  border: 3px solid #ff4e2a;
}

.slideContent img {
  aspect-ratio: 4/2;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 0 4px 2px #00000020;
  object-fit: cover;
  user-select: none;
}

.slideContent h2 {
  text-align: center;
  font-size: clamp(1rem, 3vw, 1rem);
  font-weight: 600;
  color: #ff4e2a;
  padding: 5px 15px 0 0;
}

.slideContent p {
  font-size: clamp(0.8rem, 3vw, 0.9rem);
  align-self: start;
  font-weight: 400;
  color: #3e4756;
  padding: 6px 20px 20px;
}

/* ------- */
/* .footer */

.footer {
  display: grid;
  grid-template-columns: 65% 35%;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 30px 8%;
  background-image: linear-gradient(
      90deg,
      transparent 0%,
      transparent 8%,
      rgba(90, 90, 90, 0.05) 8%,
      rgba(90, 90, 90, 0.05) 21%,
      transparent 21%,
      transparent 100%
    ),
    linear-gradient(
      45deg,
      transparent 0%,
      transparent 23%,
      rgba(90, 90, 90, 0.05) 23%,
      rgba(90, 90, 90, 0.05) 37%,
      transparent 37%,
      transparent 100%
    ),
    linear-gradient(90deg, #f7cad9, #f3b7aa);
}

.followUs h2 {
  font-size: clamp(1.2rem, 3vw, 1.4rem);
  font-weight: 700;
  line-height: 1.5;
}

.followUsList {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 14px;
}

.followUsList i {
  font-size: 2rem;
  color: #3e4756;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.2s linear;
}

.followUsList i:is(:hover, :focus) {
  transform: scale(1.1);
}

.signupAppPart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.signupAppPart p {
  font-size: clamp(0.8rem, 3vw, 1rem);
  font-weight: 500;
  line-height: 1.5;
  text-align: justify;
}

.signupBtn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 18px;
  border: 1px solid #3e4756;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 700;
  color: rgb(37, 37, 37);
  box-shadow: 0 0 4px 2px #00000020;
  background: #fff;
  transform: scale(1);
  transition: all 0.4s ease;
  cursor: pointer;
}

.signupBtn:hover {
  transform: scale(0.97);
}

/* ------------- */
/* MEDIA QUERIES */

@media (max-width: 1400px) {
  .home .item {
    max-width: 220px;
  }

  .home {
    padding: 20px 8% 0;
  }
}

@media (max-width: 1200px) {
  .header {
    padding: 16px 6%;
  }

  .logo {
    font-size: 1.2rem;
  }

  .logo i {
    font-size: 1.6rem;
    margin-right: 4px;
  }

  .home {
    gap: 30px;
  }
}

@media (max-width: 1000px) {
  .header {
    padding: 16px 5%;
  }

  .navItem {
    margin-right: 1rem;
  }

  .home {
    min-height: fit-content;
  }

  .home .content h1 {
    font-weight: 600;
  }

  .imageContainer .item {
    max-width: 170px;
  }

  .gettingStarted {
    min-height: fit-content;
    padding-top: 20px;
  }

  .explore {
    min-height: fit-content;
    padding-top: 60px;
  }

  .contact-container {
    grid-template-columns: 45% 45%;
    gap: 50px;
    width: 90%;
  }

  .footer {
    grid-template-columns: 60% 35%;
  }

  .signupBtn {
    margin: 0 auto;
  }
}

@media (max-width: 800px) {
  .logo {
    font-size: 1rem;
  }

  .logo i {
    font-size: 1.2rem;
    margin-right: 4px;
  }

  .home {
    grid-template-columns: 1fr;
    /* grid-template-rows: 50% 50%; */
    grid-template-areas:
      'imageContainer'
      'content';
  }

  .imageContainer {
    grid-area: imageContainer;
    margin-top: 50px;
  }

  .home .content {
    grid-area: content;
    text-align: center;
  }

  .gettingStarted {
    padding-top: 50px;
  }

  .gettingStarted .cardContainer {
    column-gap: 20px;
  }

  .contact-container {
    grid-template-columns: 1fr;
    grid-template-rows: 35% 60%;
    grid-template-areas:
      'contact-content'
      'form-container';
    gap: 24px;
    width: 90%;
    padding: 40px 30px 60px;
    margin: 40px 0 80px;
  }

  .contact-content {
    grid-area: contact-content;
    text-align: center;
    padding-right: 0;
  }

  form {
    grid-area: form-container;
  }
}

@media (max-width: 750px) {
  .menuIcon {
    display: block;
  }

  .navBar {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    top: 100%;
    right: -100%;
    width: 160px;
    height: 22vh;
    background: #ff0054;
    border-radius: 10px;
    padding: 20px 20px 10px;
    transition: all 0.7s ease;
  }

  .navBar li {
    list-style: none;
    margin-right: unset;
    margin-bottom: 12px;
  }
  .navBar.active {
    right: 6%;
  }

  .btn {
    padding: 10px 12px;
    font-size: 0.9rem;
    margin-right: 16px;
  }

  .gettingStarted .cardContainer {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0;
    row-gap: 30px;
  }

  .footer {
    grid-template-columns: 50% 45%;
    padding: 30px 6%;
  }

  .followUsList i {
    font-size: 1.5rem;
  }

  .signupBtn {
    margin: unset;
    padding: 8px 12px;
    font-size: 0.9rem;
  }

  .signupAppPart {
    align-items: flex-end;
    gap: 10px;
  }

  .signupAppPart p {
    text-align: right;
  }
}

@media (max-width: 576px) {
  .swiperSlide {
    max-width: 100%;
  }

  .slideContent {
    grid-template-rows: 3fr 1fr 3fr;
  }

  .slideContent img {
    aspect-ratio: 4/2;
    border-radius: 8px 8px 0 0;
    object-fit: cover;
    user-select: none;
  }
}

@media (max-width: 420px) {
  .explore h1::before {
    top: 92%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 40%;
    height: 12px;
  }
}
