.mapContainer {
  margin: 10px auto 0px auto;
  height: 85vh;
  width: 90%;
  border-radius: 10px;
  box-shadow:
    0 6.7px 5.3px rgba(0, 0, 0, 0.03),
    0 22.3px 17.9px rgba(202, 150, 150, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07);
  border: 1px solid rgb(173, 173, 173, 0.5);
}

@media (max-width: 1000px) {
  .mapContainer {
    width: 88%;
    margin-bottom: 2rem;
  }
}

@media (max-width: 576px) {
  .mapContainer {
    height: 65vh;
    margin-bottom: 2rem;
  }
}
