.statusHeader {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  margin-bottom: 0px;
}

.pClosed {
  margin: 0 10px 0 10px;
  color: #08c72edd;
}

.iconClosed {
  color: #08c72edd;
  font-size: 1.1em;
  margin-top: 0.15em;
}

.pInactive {
  margin: 0 10px 0 10px;
}

.iconInactive {
  font-size: 1.1em;
  margin-top: 0.15em;
}
