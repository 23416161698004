.signUpForm {
  margin: auto;
  min-width: 56%;
}

.asterisk {
  color: #ff2a70;
}

.spinner {
  margin: auto;
  margin-bottom: 1rem;
}

@media screen and (max-width: 992px) {
  .signUpForm {
    min-width: 87%;
  }
}
