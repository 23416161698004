.profileImg {
  width: 30px;
}

.cloudImg {
  border-radius: 50%;
  pointer-events: none;
}

.p {
  font-size: 0.7rem;
  line-height: 1.4;
  color: #4b5563;
  margin-bottom: 8px;
}

.hr {
  margin: 0 auto!important;
  margin-bottom: 1em!important;
  padding: 0;
  width: 87%;
}

.cardHeader {
  background-color: inherit;
  border-bottom: 0;
  font-size: 0.9em;
}

.cardFooter {
  background-color: inherit;
  border: 0;
  font-size: 0.9em;
}

.acceptOfferBtn {
  padding: 0.4em 1.5em;
}