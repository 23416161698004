.loginForm {
  margin: auto;
  min-width: 56%;
}

a:hover {
  color: #7f7f85;
}

.checkboxCol {
  font-size: 0.875rem;
  padding-left: 0;
}

.forgotPwdCol {
  font-size: 0.875rem;
  padding: 0;
  text-align: right;
}

.spinner {
  margin: auto;
  margin-bottom: 1rem;
}

@media screen and (max-width: 575px) {
  .forgotPwdCol {
    text-align: left;
  }
}

@media screen and (max-width: 500px) {
  .loginForm {
    max-width: 100%;
  }
}
