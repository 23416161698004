@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  position: relative;
  position: relative;
  scroll-behavior: smooth;
  min-height: 100dvh;
}

body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  /* background: linear-gradient(#ff8e6a61, white 77%); */
  background-image: radial-gradient(at 3.6% 13.8%, #fad3ca 0px, transparent 50%),
    radial-gradient(at 96.3% 93.8%, #f8d5e0 0px, transparent 50%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3 {
  color: #030303;
  line-height: 31px;
}

h2 {
  color: #1e1f26;
  margin-bottom: 0.6rem;
}

h3 {
  font-weight: normal;
}

a {
  color: inherit;
  text-decoration: none;
}

/* Override Bootstrap default variables */

:root {
  --bs-font-sans-serif: 'Poppins', system-ui, Roboto, 'Helvetica Neue', 'Noto Sans',
    'Liberation Sans', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}

.btn {
  transition: all 0.3s ease-in-out;
}

.btn-primary {
  --bs-btn-bg: #ff2a70;
  --bs-btn-border-color: #ff2a70;
}

.btn-primary,
.btn-outline-dark {
  --bs-btn-hover-bg: #ff0054;
  --bs-btn-hover-border-color: #ff0054;
  --bs-btn-active-bg: #fe496f;
  --bs-btn-active-border-color: #fe496f;
  --bs-btn-disabled-bg: #fe496f;
  --bs-btn-disabled-border-color: #fe496f;
}

.btn-danger {
  --bs-btn-bg: #ff4e2a;
  --bs-btn-border-color: #ff4e2a;
  --bs-btn-hover-bg: #ff2b00;
  --bs-btn-hover-border-color: #ff2b00;
  --bs-btn-active-bg: #e92700;
  --bs-btn-active-border-color: #e92700;
}

.form-check-input:checked {
  background-color: #ff2a70;
  border-color: #ff2a70;
}

.form-control:focus,
.form-check-input:focus,
.form-select:focus {
  border-color: #ffeef3;
  box-shadow: 0 0 0 0.25rem rgb(255 155 128 / 9%);
}

.spinner-border {
  border: 0.25em #ff2a70 solid;
  border-right-color: transparent;
  display: block;
}

.navbar {
  --bs-navbar-toggler-padding-x: 0.7rem;
  --bs-navbar-toggler-font-size: 1.1rem;
  --bs-navbar-toggler-focus-width: 0.1rem;
}
